<template>
  <div class="log">
    <v-btn
      style="width: 30px; height: 30px; cursor: pointer"
      outline
      small
      fab
      v-if="this.$route.name === 'Accounts'"
      @click="assignmentList($event)"
      color="orange"
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <button
      v-if="this.$route.name !== 'Accounts'"
      color="orange"
      outline
      class="pay-ass-btn padding_all"
      type="button"
      @click="getList()"
    >
      Details
    </button>
    <v-dialog v-model="dialog" max-width="98%" persistent>
      <v-form ref="form">
        <v-card style="padding: 0px !important; overflow-x: hidden">
          <v-toolbar
            dark
            style="background-color: #dcdcdc; color: black; padding-left: 10px"
            flat
          >
            <v-toolbar-title>Additional Charges</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="dialog = false">
              <v-icon dark> close </v-icon>
            </v-btn>

            <!-- <v-btn icon>
              <v-icon
                class="heading grey--text text--darken-4"
                @click.prevent="dialog = false"
                >close</v-icon
              >
            </v-btn> -->
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="customScrollAir">
            <v-card-text class="nospace">
              <v-layout style="border-top: 1px solid #efefef" class="p-16">
                <v-flex xs3 pt-3 v-if="this.$route.name == 'Accounts'">
                  <v-select
                    :items="AssignmentId"
                    label="Assignment Id*"
                    v-model="ids"
                    item-text="text"
                    item-value="value"
                    class="pt-0 currencyTitle"
                    @change="getListInAccountSection"
                    single-line
                  ></v-select>
                </v-flex>
                <v-flex
                  xs6
                  pt-3
                  pl-3
                  v-if="
                    this.$route.name != 'Accounts ' && totalAddtionalCharges
                  "
                  ><span class="dashboard-style"
                    >Driver Total Additional Charge :</span
                  >{{ totalAddtionalCharges }}
                  {{ totalAddtionalCharges ? jobCurrency : "" }}</v-flex
                >
                <v-flex xs6 pt-3 v-if="totalCostMargin"
                  ><span class="dashboard-style">
                    Customer Total Additional Charge :</span
                  >{{ totalCostMargin }} {{ jobCurrency }}</v-flex
                >
                <v-flex xs6 pt-3 pl-2 v-if="totalMiscellaneousChargePopup"
                  ><span class="dashboard-style">
                    Miscell. Total Additional Charge:</span
                  >{{ totalMiscellaneousChargePopup }} {{ jobCurrency }}</v-flex
                >

                <v-flex
                  style="text-align: end"
                  class="pb-2"
                  v-if="this.$route.name == 'Accounts'"
                >
                  <div
                    v-if="amountPaidToDriver <= 0 || amountPaidToDriver2 <= 0"
                  >
                    <AddAdditionalCharges
                      v-if="
                        checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesGenSameComp
                        )
                      "
                      v-permissions="'additional-charge-for-accounts'"
                      :assId="assignID"
                      :jobId="this.jobId"
                      :chargesType="chargesType"
                      :destinationCountry="destinationCountry"
                      :sourceCountry="sourceCountry"
                      :destinationCity="destinationCity"
                      :VendorWorkingCountry="VendorWorkingCountry"
                      :status="status"
                      :viewOnly="viewOnly"
                      @successAdditionalCharges="closeMessage()"
                      :assign_id="this.assignId"
                      :currency="jobCurrency"
                      :customerId="customerId"
                    />

                    <AddAdditionalCharges
                      v-if="
                        !checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesGenSameComp
                        )
                      "
                      :assId="assignID"
                      :jobId="this.jobId"
                      :chargesType="chargesType"
                      :destinationCountry="destinationCountry"
                      :sourceCountry="sourceCountry"
                      :destinationCity="destinationCity"
                      :VendorWorkingCountry="VendorWorkingCountry"
                      :status="status"
                      :viewOnly="viewOnly"
                      @successAdditionalCharges="closeMessage()"
                      :assign_id="this.assignId"
                      :currency="jobCurrency"
                      :customerId="customerId"
                    />
                  </div>
                </v-flex>
                <v-flex style="text-align: end" class="pb-2" v-else>
                  <div v-if="amountPaidToDriver <= 0">
                    <div
                      v-if="
                        checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesInvoice
                        )
                      "
                    >
                      <AddAdditionalCharges
                        v-if="!isCustomerReceiptInitiated"
                        :assId="assignID"
                        v-permissions="'edit-additional-charges'"
                        :jobId="this.jobId"
                        :chargesType="chargesType"
                        :destinationCountry="destinationCountry"
                        :sourceCountry="sourceCountry"
                        :destinationCity="destinationCity"
                        :VendorWorkingCountry="VendorWorkingCountry"
                        :status="status"
                        :viewOnly="viewOnly"
                        @successAdditionalCharges="closeMessage()"
                        :assign_id="this.assignId"
                        :currency="jobCurrency"
                        :customerId="customerId"
                      />
                    </div>
                    <div
                      v-if="
                        !checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesInvoice
                        )
                      "
                    >
                      <AddAdditionalCharges
                        :assId="assignID"
                        v-permissions="'add-additional-charges-master'"
                        :jobId="this.jobId"
                        :chargesType="chargesType"
                        :destinationCountry="destinationCountry"
                        :sourceCountry="sourceCountry"
                        :destinationCity="destinationCity"
                        :VendorWorkingCountry="VendorWorkingCountry"
                        :status="status"
                        :viewOnly="viewOnly"
                        @successAdditionalCharges="closeMessage()"
                        :assign_id="this.assignId"
                        :currency="jobCurrency"
                        :customerId="customerId"
                      />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-data-table
              id="ListTable"
              hide-actions
              :headers="headers"
              :items="items"
              style="word-break: break-word"
            >
              <template slot="items" slot-scope="props">
                <td
                  style="width: 10%"
                  v-if="
                    props.item.costMargin != 0 &&
                    props.item.miscellaneousCharge == 0
                  "
                >
                  {{ props.item.chargesType || "Exclusive" }}
                </td>
                <td v-else>N.A</td>
                <td style="width: 10%">
                  {{
                    Number.isInteger(props.item.costMargin)
                      ? props.item.costMargin
                      : props.item.costMargin.toFixed(2)
                  }}
                  {{ jobCurrency }}
                </td>

                <td style="width: 10%">
                  {{
                    Number.isInteger(props.item.amount)
                      ? props.item.amount
                      : props.item.amount.toFixed(2)
                  }}
                  {{ jobCurrency }}
                </td>
                <td style="width: 10%">
                  {{ props.item.miscellaneousCharge }}
                  {{ jobCurrency }}
                </td>
                <td style="width: 20%">{{ props.item.comments }}</td>
                <td style="width: 20%">
                  {{ props.item.description ? props.item.description : "N.A." }}
                </td>
                <td style="width: 20%" v-if="props.item.editDate">
                  {{ getTime3(props.item.editDate) }}
                </td>
                <td v-else></td>
                <td>
                  {{ props.item.userData ? props.item.userData.name : "-"
                  }}<br />({{
                    props.item.userData ? props.item.userData.employeeID : ""
                  }})
                </td>
                <td
                  style="width: 10%"
                  v-if="props.item.documentForAdditionalCharges.length"
                >
                  <v-flex xs8 justify-content: center>
                    <ul
                      style="
                        position: relative;
                        list-style-type: none;
                        display: inline-block;
                        width: 500px;
                      "
                    >
                      <li
                        :key="i"
                        v-for="(doc, i) in props.item
                          .documentForAdditionalCharges"
                        class="list"
                        style="display: inline-block"
                        ref="documentList"
                      >
                        <a
                          :href="doc"
                          class="document-link"
                          style="text-decoration: none; outline: none"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                        >
                          <img
                            src="../../assets/pfd.png"
                            height="40"
                            width="32"
                          />
                        </a>
                        <a
                          :href="doc"
                          style="text-decoration: none; outline: none"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../assets/docimg.webp"
                            height="40"
                            width="40"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-flex>
                </td>
                <td v-else>-</td>

                <td style="width: 20%" v-if="$route.name == 'Accounts'">
                  <div
                    v-if="amountPaidToDriver <= 0 || amountPaidToDriver2 <= 0"
                  >
                    <v-layout
                      class="justfy_c"
                      v-if="
                        checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesGenSameComp
                        )
                      "
                    >
                      <EditAdditionalCharges
                        v-if="!isCustomerReceiptInitiated && status != 1"
                        v-permissions="'additional-charge-for-accounts'"
                        :currency="jobCurrency"
                        :customerId="customerId"
                        :chargesTypeJob="chargesType"
                        :assignId="assignID || this.ids"
                        :assignDetails="props.item"
                        :status="status"
                        :viewOnly="viewOnly"
                        :destinationCity="destinationCity"
                        :destinationCountry="destinationCountry"
                        :sourceCountry="sourceCountry"
                        :VendorWorkingCountry="VendorWorkingCountry"
                        :jobId="jobId"
                        :chargesType="props.item.chargesType"
                        @editAdditionalSuccess="closeMessage()"
                        :assign_id="assignId"
                      />

                      <button
                        v-if="!isCustomerReceiptInitiated"
                        class="track-ass-btn red-button"
                        :disabled="status == 1 || loading"
                        type="button"
                        @click="open(props.item, props.index)"
                        title="Delete"
                      >
                        Delete
                      </button>
                    </v-layout>
                    <v-layout
                      v-if="
                        !checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesGenSameComp
                        )
                      "
                    >
                      <EditAdditionalCharges
                        v-if="!isCustomerReceiptInitiated && status != 1"
                        v-permissions="'edit-additional-charges-master'"
                        :currency="jobCurrency"
                        :customerId="customerId"
                        :chargesTypeJob="chargesType"
                        :assignId="assignID || this.ids"
                        :assignDetails="props.item"
                        :status="status"
                        :viewOnly="viewOnly"
                        :destinationCity="destinationCity"
                        :destinationCountry="destinationCountry"
                        :sourceCountry="sourceCountry"
                        :VendorWorkingCountry="VendorWorkingCountry"
                        :jobId="jobId"
                        :chargesType="props.item.chargesType"
                        @editAdditionalSuccess="closeMessage()"
                        :assign_id="assignId"
                      />

                      <button
                        v-permissions="'delete-additional-charges-master'"
                        class="track-ass-btn red-button"
                        :disabled="status == 1 || loading"
                        type="button"
                        @click="open(props.item, props.index)"
                        title="Delete"
                      >
                        Delete
                      </button>
                    </v-layout>
                  </div>
                </td>
                <td style="width: 20%" v-if="$route.name != 'Accounts'">
                  <div v-if="amountPaidToDriver <= 0">
                    <v-layout
                      style="justify-content: center"
                      v-if="
                        checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesInvoice
                        )
                      "
                    >
                      <EditAdditionalCharges
                        v-if="!isCustomerReceiptInitiated && status != 1"
                        v-permissions="'edit-additional-charges'"
                        :currency="jobCurrency"
                        :customerId="customerId"
                        :chargesTypeJob="chargesType"
                        :assignId="assignID || this.ids"
                        :assignDetails="props.item"
                        :viewOnly="viewOnly"
                        :destinationCity="destinationCity"
                        :VendorWorkingCountry="VendorWorkingCountry"
                        :destinationCountry="destinationCountry"
                        :sourceCountry="sourceCountry"
                        :status="status"
                        :jobId="jobId"
                        :chargesType="props.item.chargesType"
                        @editAdditionalSuccess="closeMessage()"
                        :assign_id="assignId"
                      />

                      <button
                        v-permissions="'edit-additional-charges'"
                        class="track-ass-btn red-button"
                        type="button"
                        v-if="status != 1 && !isCustomerReceiptInitiated"
                        @click="open(props.item, props.index)"
                        title="Delete"
                        :disabled="loading"
                      >
                        Delete
                      </button>
                    </v-layout>
                    <v-layout
                      style="justify-content: center"
                      v-if="
                        !checkInvoiceGenerated(
                          jobSalesInvoice,
                          assignSalesInvoice
                        )
                      "
                    >
                      <EditAdditionalCharges
                        v-permissions="'edit-additional-charges-master'"
                        :currency="jobCurrency"
                        :customerId="customerId"
                        :chargesTypeJob="chargesType"
                        :assignId="assignID || this.ids"
                        :assignDetails="props.item"
                        :viewOnly="viewOnly"
                        :status="status"
                        :jobId="jobId"
                        :chargesType="props.item.chargesType"
                        @editAdditionalSuccess="closeMessage()"
                        :assign_id="assignId"
                      />

                      <button
                        v-permissions="'delete-additional-charges-master'"
                        class="track-ass-btn red-button"
                        type="button"
                        v-if="status != 1"
                        @click="open(props.item, props.index)"
                        title="Delete"
                        :disabled="loading"
                      >
                        Delete
                      </button>
                    </v-layout>
                  </div>
                </td>
              </template>
            </v-data-table>

            <span class="page-count-span totalpages"
              >Total Pages - {{ totalPages }}</span
            >

            <PaginationButtons
              v-if="dialog"
              :url="`/dmsAdmin/getAdditionalCharges`"
              :payload="payload"
              :callback="callback"
              :componentKey="componentKey"
              v-on:pagination-payload="paginationPayload"
            />
            <success-dialog
              :content="x.message"
              :show="x.success"
              :onclose="closeMessage"
            />
            <v-dialog
              v-model="dialogDelete"
              persistent
              :max-width="options.width"
              :style="{ zIndex: options.zIndex }"
              @keydown.esc="cancel"
            >
              <v-card>
                <loading :active.sync="loading" />
                <v-toolbar dark :color="options.color" dense flat>
                  <v-toolbar-title class="white--text">{{
                    title
                  }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text v-show="!!message" class="pa-4">
                  {{ message }}
                  <v-text-field
                    type="text"
                    label="Please add comments here"
                    required
                    v-model="statusComments"
                    :value="statusComments"
                  />
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    color="primary darken-1"
                    text
                    @click.native="agree"
                    :disabled="loading"
                    >Yes</v-btn
                  >
                  <v-btn
                    color="grey"
                    text
                    :disabled="loading"
                    @click.native="cancel"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-snackbar
              :timeout="3000"
              color="red darken-2"
              v-model="error"
              class="white--text"
              v-if="error"
              >{{ error }}</v-snackbar
            >
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import SuccessDialog from "@/components/Common/SuccessDialog";
import AddAdditionalCharges from "@/components/Pop-ups/AddAdditionalCharges";
import EditAdditionalCharges from "@/components/Pop-ups/EditAdditionalCharges";
import { additionalChargesList } from "@/constants/datatable-headers.js";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import { StorageKeys, JobTypes, JobListTypes } from "../../constants/constants";
import { async } from "q";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    window.addEventListener("online", () => {
      this.componentKey += 1;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
          this.currency = key.currency;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });

    this.payload = {
      skip: "0",
      limit: "15",
      assignId:
        this.$route.name == "Accounts" ? this.ids : this.$props.isAssign,
      workingCountry: this.workingCountry,
    };
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("success");
    this.$eventBus.$off("edit-success");
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ConfirmationDialog,
    ErrorBox,
    Loading,
    AddAdditionalCharges,
    EditAdditionalCharges,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
    viewOnly: Boolean,
    isAssign: String,
    _id: String,
    id: Number,
    jobCurrency: String,
    isCustomerReceiptInitiated: Boolean,
    jobSalesInvoice: Boolean,
    assignSalesInvoice: Boolean,
    amountPaidToDriver: Number,
  },

  data() {
    return {
      workingCountry: null,
      assignSalesGenSameComp: null,
      totalAddtionalCharges: 0,
      VendorWorkingCountry: "",
      totalCostMargin: 0,
      totalMiscellaneousChargePopup: 0,
      amountPaidToDriver: 0,
      additionalchargesId: "",
      destinationCity: "",
      ids: "",
      title: null,
      assignId: "",
      message: null,
      statusComments: "",
      dialog: false,
      dialogDelete: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      operationName: "",
      chargesType: "",
      customerStatus: "",
      customerId: null,
      JobTypes: [],
      jobId: "",
      status: null,
      assignID: null,
      destinationCountry: "",
      sourceCountry: "",
      currency: "",
      AssignmentId: [],
      jobType: "",
      error: "",
      documents: [],
      document: [],
      loading: true,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {},

      headers: additionalChargesList,
      items: [],
    };
  },
  watch: {},
  methods: {
    assignmentList(e) {
      this.AssignmentId = [];
      e.stopPropagation();
      this.loading = true;
      const data = {
        JobId: this.$props.id.toString(),
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/assignmentList`, data, config).then(
        (response) => {
          this.ids = response.data.data.data[0]._id;
          response.data.data.data.forEach((e) => {
            this.AssignmentId.push({
              value: e._id,
              text: e.assignmentId,
            });
          });
          this.loading = false;
          this.getListInAccountSection();
        },

        (error) => {
          this.error = error.response.data.message;
          this.loading = false;
        }
      );
    },

    checkInvoiceGenerated(joblevel, SalesLevel) {
      // console.log(joblevel, SalesLevel);
      if (!joblevel && !SalesLevel) {
        return true;
      } else {
        return false;
      }
    },
    open(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      this.statusComments = "";
      this.additionalchargesId = val._id;
      this.dialogDelete = true;
      this.title = "Confirmation";
      this.message = "Are you sure you want to delete this additional charge?";
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      if (this.statusComments.trim()) {
        this.loading = true;
        this.deleteDevice();
      } else {
        this.error = "Please provide comments before moving ahead";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return false;
      }
    },
    cancel() {
      this.resolve(false);
      this.dialogDelete = false;
    },
    getList() {
      this.dialog = true;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
            this.currency = key.currency;
          }
        });
      }

      EventBus.$on("countryChanged", (val) => {
        if (this.$route.name == "documents") {
          this.$router.push(`/jobs`);
        }
        this.workingCountry = val.value;
        this.loading = true;
      });

      this.payload = {
        skip: "0",
        limit: "15",
        assignId:
          this.$route.name == "Accounts" ? this.ids : this.$props.isAssign,
        workingCountry: this.workingCountry,
      };
    },
    getListInAccountSection() {
      // console.log("--login");

      this.assignSalesGenSameComp = "";
      this.dialog = true;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
            this.currency = key.currency;
          }
        });
      }

      EventBus.$on("countryChanged", (val) => {
        // if (this.$route.name == "documents") {
        //   this.$router.push(`/jobs`);
        // }
        this.workingCountry = val.value;
        this.loading = true;
      });

      this.payload = {
        skip: "0",
        limit: "15",
        assignId:
          this.$route.name == "Accounts" ? this.ids : this.$props.isAssign,
        workingCountry: this.workingCountry,
      };
    },
    async deleteDevice() {
      this.loading = true;
      this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        let Url = "/dmsAdmin/deleteAdditionalCharges";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: this.jobId,
          assignmentId: this.assignId,
          additionalchargesId: this.additionalchargesId,
          comments: this.statusComments,
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            // this.loading = false;
            this.dialogDelete = false;
            this.x.success = false;
            this.componentKey += 1;
            this.$emit("assignment-list-refresh");
            if (this.$route.name == "Accounts") {
              this.$emit("account-list-refresh");
            } else {
              this.$eventBus.$emit("refresh-job");
            }
          },
          (error) => {
            this.x.success = false;
            this.dialogDelete = false;
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },
    closeMessage() {
      if (this.$route.name == "Accounts") {
        this.$emit("account-list-refresh");
        this.amountPaidToDriver = 0;
      }
      this.$emit("assignment-list-refresh");
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    callback(res) {
      this.totalAddtionalCharges = 0;
      this.totalCostMargin = 0;
      this.totalMiscellaneousChargePopup = 0;
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.items = res.list;
      this.jobId = res.jobId;
      this.chargesType = res.chargesType;
      console.log(res.totalMiscellaneousCharge);
      if (res.totalMiscellaneousCharge != 0) {
        this.totalMiscellaneousChargePopup = Number.isInteger(
          res.totalMiscellaneousCharge
        )
          ? res.totalMiscellaneousCharge
          : res.totalMiscellaneousCharge.toFixed(2);
      }
      if (res.totalAdditionalAmount != 0) {
        this.totalAddtionalCharges = Number.isInteger(res.totalAdditionalAmount)
          ? res.totalAdditionalAmount
          : res.totalAdditionalAmount.toFixed(2);
      }
      if (res.totalCostMargin) {
        if (res.totalCostMargin != 0) {
          this.totalCostMargin = Number.isInteger(res.totalCostMargin)
            ? res.totalCostMargin
            : res.totalCostMargin.toFixed(2);
        }
      }
      // console.log(res.assignmentDetails[0].isSalesInvoiceGenerated);
      // console.log(this.jobSalesInvoice);

      this.assignSalesGenSameComp =
        res.assignmentDetails[0].isSalesInvoiceGenerated;
      this.assignID = res.assignmentDetails[0].assignmentId;
      this.destinationCity = res.assignmentDetails[0].destinationCity;
      if (res.assignmentDetails[0].WorkingCountry) {
        this.VendorWorkingCountry = res.assignmentDetails[0].WorkingCountry;
      } else {
        this.VendorWorkingCountry =
          res.assignmentDetails[0].VendorWorkingCountry;
      }

      this.destinationCountry = res.assignmentDetails[0].destinationCountry;
      this.sourceCountry = res.assignmentDetails[0].sourceCountry;
      this.amountPaidToDriver = res.assignmentDetails[0].amountPaidToDriver;
      this.amountPaidToDriver2 = res.assignmentDetails[0].amountPaidToDriver;

      this.assignId = res.assignmentDetails[0]._id;
      this.status = res.assignmentDetails[0].assignmentStatus;
      this.customerId = res.assignmentDetails[0].customerId;
      this.totalPages = Math.ceil(res.totalData / 15);
      this.loading = false;
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style scoped>
.customScrollAir {
  overflow-y: scroll !important;
  max-height: calc(100vh - 200px) !important;
}
</style>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.orange-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 5px 15px;
}
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.p-16 {
  padding: 8px !important;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}

.white-button {
  border-radius: 5px;
  background-color: white !important;
  color: grey !important;
  border: 2px solid grey !important;
}
.blue-button {
  border-radius: 5px;
  background-color: blue !important;
  color: #ffffff !important;
  border: 2px solid blue !important;
}
.yellow-button {
  border-radius: 5px;
  background-color: #ffd65c !important;
  color: #ffffff !important;
  border: 2px solid #ffc107 !important;
}
.greyy-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: gray;
  border-radius: 5px;
  border: 2px solid gray;
  padding: 2px 5px;
}

.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
</style>
