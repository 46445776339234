<template>
  <div class="log">
    <v-btn
      small
      @click="getList()"
      v-if="section === 'ReadyToPay' && isVisible !== 0"
      class="payy-ass-btn green-button"
    >
      Adv. Ready To Pay</v-btn
    >

    <v-btn
      style="width: 30px; height: 30px; cursor: pointer"
      outline
      small
      fab
      v-if="this.$route.name == 'Accounts' && !section"
      @click="getList()"
      color="orange"
    >
      <v-icon>edit</v-icon>
    </v-btn>

    <button
      v-if="this.$route.name != 'Accounts' && !section"
      color="orange"
      outline
      class="pay-ass-btn padding_all"
      type="button"
      @click.stop="dialog = false"
      @click="getList()"
    >
      Details
    </button>
    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-form ref="form" onsubmit="return false;" class="layout__list">
        <v-card style="padding: 0px !important">
          <v-toolbar
            class="pd__left__10"
            dark
            style="background-color: #dcdcdc; color: black"
            flat
          >
            <v-toolbar-title>Request Advance Details </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="dialog = false">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-card-text class="nospace">
                <loading :active.sync="loading" />

                <v-layout
                  style="
                    border-top: 1px solid #efefef;
                    justify-content: end;
                    white-space: nowrap;
                    padding-left: 7px;
                  "
                >
                  <v-flex xs7 pt-2 pl-1 v-if="advanceAmount"
                    ><span class="dashboard-style"
                      >Total Advance Requested:
                    </span>
                    {{ advanceAmount }} {{ currency }}</v-flex
                  >
                  <v-flex xs6 pt-2 pl-2 v-if="totaladvancePaid"
                    ><span class="dashboard-style">Total Advance Paid: </span>
                    {{ totaladvancePaid }} {{ currency }}</v-flex
                  >
                  <v-flex xs6 pt-2 pl-2 v-if="totaldata"
                    ><span class="dashboard-style">Driver Price: </span
                    >{{ paymentAmount }} {{ currency }}</v-flex
                  >

                  <v-flex
                    xs12
                    class="button__align"
                    pl-4
                    v-if="!purchaseInvoiceGenerated"
                  >
                    <ReadyToPayPopup
                      v-if="$props.section !== 'Accounts' && isMarkedAllRead"
                      :confirmationTitle="'Comments'"
                      :cancelBtn="'Cancel'"
                      :confirmBtn="'Add Comment'"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      :assignId="assignId"
                      :assignStatus="status"
                      @refresh-list="refreshList()"
                      :type="'all'"
                    />

                    <GenerateAdvance
                      :assId="assignID"
                      v-if="
                        this.$route.name != 'Accounts' &&
                        $props.section !== 'Accounts'
                      "
                      v-permissions="'generate-advance-jobs'"
                      :status="status"
                      :viewOnly="viewOnly"
                      :assignedToTransporter="assignedToTransporter"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      :ownerType="ownerType"
                      :currency="currency"
                      :invoiceCity="invoiceCity"
                      :invoiceCompany="invoiceCompany"
                      :maximumAdvanceLeftAmount="maximumAdvanceLeftAmount"
                      :additionalCharges="$props.additionalCharges"
                      @generateAdvance="closeMessage()"
                      :totalAmount="this.paymentAmount"
                      :totalAdvanceAmount="advanceAmount"
                      :totaladvancePaid="totaladvancePaid"
                    />

                    <GenerateAdvance
                      :assId="assignID"
                      v-if="
                        this.$route.name == 'Accounts' &&
                        $props.section !== 'Accounts'
                      "
                      v-permissions="'add-advance-for-accounts'"
                      :assignedToTransporter="assignedToTransporter"
                      :ownerType="ownerType"
                      :status="status"
                      :viewOnly="viewOnly"
                      :currency="currency"
                      :invoiceCity="invoiceCity"
                      :invoiceCompany="invoiceCompany"
                      :maximumAdvanceLeftAmount="maximumAdvanceLeftAmount"
                      @generateAdvance="closeMessage()"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      :totalAmount="this.paymentAmount"
                      :additionalCharges="$props.additionalCharges"
                      :totalAdvanceAmount="this.advanceAmount"
                      :totaladvancePaid="totaladvancePaid"
                    />

                    <v-btn
                      class="ma-2"
                      v-if="
                        this.$route.name != 'Accounts' &&
                        $props.section !== 'Accounts' &&
                        advanceStatus
                      "
                      outline
                      v-permissions="'cancel-advance'"
                      color="red"
                      @click="check('all')"
                      :disabled="
                        status <= 1 ||
                        status === 4 ||
                        customerStatus == 'view' ||
                        purchaseInvoiceGenerated
                      "
                    >
                      Cancel All Advance
                    </v-btn>
                    <v-btn
                      class="ma-2"
                      v-if="
                        this.$route.name == 'Accounts' &&
                        advanceStatus &&
                        $props.section !== 'Accounts'
                      "
                      outline
                      v-permissions="'add-advance-for-accounts'"
                      color="red"
                      @click="check()"
                      :disabled="
                        status <= 1 ||
                        (status === 4 && customerStatus == 'view') ||
                        !purchaseInvoiceGenerated
                      "
                    >
                      Cancel All Advance
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-data-table
                id="TransporterListTable"
                hide-actions
                :headers="headers"
                :items="items"
                style="word-break: break-word"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    <v-layout row justify-center>
                      <activityLogs
                        :subType="17"
                        :id="props.item._id"
                        :type="'advance'"
                      />
                      <span class="pl-1 pt-1">
                        {{ props.item.advanceAmount }} {{ currency }}</span
                      >
                    </v-layout>
                  </td>
                  <td v-if="props.item.actualAmountPaid">
                    {{ props.item.actualAmountPaid }} {{ currency }}
                  </td>
                  <td v-else>N.A</td>
                  <td v-if="props.item.updatedAt">
                    {{ props.item.employeeName }}({{
                      props.item.employeeId
                    }})<br />
                    {{ getTime3(props.item.updatedAt) }}
                  </td>
                  <td v-else>
                    {{ props.item.employeeName }}({{ props.item.employeeId }})
                  </td>
                  <td v-if="props.item.creditorModeOfPayment == 'Cash'">
                    {{ props.item.creditorModeOfPayment }}
                  </td>
                  <td
                    v-else-if="
                      props.item.creditorModeOfPayment == 'Bank Transfer'
                    "
                  >
                    {{ props.item.creditorModeOfPayment }}<br />
                    Swift No. -
                    {{ props.item.swiftNumber || "N.A" }} <br />
                    IBAN No. -
                    {{ props.item.IBANNumber || "N.A" }}<br />
                    Account No. -
                    {{ props.item.accountNumber || "N.A" }} <br />Account Holder
                    Name-
                    {{ props.item.accountHolderName || "N.A" }}
                  </td>
                  <td v-else>-</td>
                  <td>
                    {{ props.item.modeOfPayment || "N.A" }}<br />
                    {{ props.item.bankName }}
                  </td>
                  <td>
                    {{
                      props.item.actualPaidDate && props.item.actualPaidTime
                        ? getTime4(props.item.actualPaidDate) +
                          " " +
                          props.item.actualPaidTime
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      assignmentDetails.driverFirstName
                        ? assignmentDetails.driverFirstName +
                          " " +
                          assignmentDetails.driverLastName
                        : ""
                    }}<br />(via {{ ownerType }})
                    <br />
                    {{ assignDetails.driverData ? assignDetails.driverId : ""
                    }}<br />
                    <span v-if="assignDetails.driverData">
                      <template v-if="assignDetails.truckNumber">
                        {{ assignDetails.truckPrefix }}-{{
                          assignDetails.truckNumber
                        }}</template
                      >
                      <template v-else>
                        {{ assignDetails.driverData.truckPrefix }}-{{
                          assignDetails.driverData.truckNumber
                        }}</template
                      >
                    </span>
                  </td>
                  <td>{{ getStatus(props.item.advanceStatus) }}</td>
                  <td>
                    <span v-if="props.item.adjustedAmount">
                      {{ props.item.adjustedAmount }} {{ currency }} <br />{{
                        props.item.remarksForAdjustment
                          ? "(" + props.item.remarksForAdjustment + ")"
                          : ""
                      }}</span
                    >
                    <span v-else>N.A</span>
                  </td>

                  <td style="word-break: break-word">
                    {{
                      props.item.cancelAdvanceReason ||
                      props.item.rejectionReason ||
                      "-"
                    }}
                  </td>
                  <td v-if="!purchaseInvoiceGenerated">
                    <ReadyToPayPopup
                      v-if="
                        $props.section !== 'Accounts' &&
                        props.item.advanceStatus === 'Issued'
                      "
                      :confirmationTitle="'Comments'"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      :details="props.item"
                      :assignStatus="assignDetails.assignmentStatus"
                      :cancelBtn="'Cancel'"
                      :assignId="assignId"
                      :confirmBtn="'Add Comment'"
                      @refresh-list="refreshList()"
                      :type="'single'"
                    />
                    <v-btn
                      v-if="
                        $props.section !== 'Accounts' &&
                        status != 4 &&
                        !purchaseInvoiceGenerated &&
                        (props.item.advanceStatus === 'Ready' ||
                          props.item.advanceStatus === 'Issued' ||
                          props.item.advanceStatus === 'Rejected')
                      "
                      class="cancel-ass-btn"
                      small
                      outline
                      v-permissions="'cancel-advance'"
                      color="red"
                      @click="check('single', props.item._id)"
                      :disabled="status <= 1 || customerStatus == 'view'"
                    >
                      Cancel
                    </v-btn>
                    <editAdvance
                      v-if="
                        props.item.advanceStatus === 'Issued' ||
                        props.item.advanceStatus === 'Ready' ||
                        props.item.advanceStatus === 'Rejected' ||
                        props.item.advanceStatus == 'PartiallyPaid'
                      "
                      :assId="assignID"
                      v-permissions="'add-advance-for-accounts'"
                      :isCancel="props.item.isCancel"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      :purchaseInvoiceGenerated="purchaseInvoiceGenerated"
                      :ownerType="ownerType"
                      :serialId="props.item._id"
                      :status="assignDetails.assignmentStatus"
                      :assignedToTransporter="assignedToTransporter"
                      :viewOnly="viewOnly"
                      :currency="currency"
                      :details="props.item"
                      :modeOfPayment="props.item.modeOfPayment"
                      :totaladvancePaid="totaladvancePaid"
                      :additionalCharges="additionalCharges"
                      :invoiceCity="invoiceCity"
                      :invoiceCompany="invoiceCompany"
                      :bankNames="props.item.bankName"
                      :totalAdvanceRequestedArray="totalAdvanceRequested"
                      :maximumAdvanceLeftAmount="maximumAdvanceLeftAmount"
                      @editAdvance="closeMessage()"
                      :advance="props.item.advanceAmount.toString()"
                      :totalAmount="paymentAmount"
                      :totalAdvanceAmount="advanceAmount"
                    />
                    <proof-of-delivery
                      v-if="
                        props.item.advanceStatus == 'Paid' &&
                        props.item.document &&
                        props.item.document.length
                      "
                      :deliveryProof="props.item.document"
                    />
                  </td>
                  <td v-else></td>
                </template>
              </v-data-table>

              <span class="page-count-span totalpages"
                >Total Pages - {{ totalPages }}</span
              >

              <PaginationButtons
                v-if="dialog"
                :url="`/dmsAdmin/getAdvanceList`"
                :payload="payload"
                :callback="callback"
                :componentKey="componentKey"
                v-on:pagination-payload="paginationPayload"
              />
              <success-dialog
                :content="x.message"
                :show="x.success"
                :onclose="closeMessage"
              />
              <v-dialog v-model="cancelDialog" max-width="40%" persistent>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card style="overflow-x: hidden">
                    <v-card-title
                      style="
                        background-color: #f5f5f5;
                        padding-bottom: 5px;
                        padding-top: 5px;
                      "
                    >
                      <span class="headline">Cancel Advance</span>
                      <v-spacer></v-spacer>
                      <v-btn icon @click.prevent="closeAdvance()">
                        <v-icon class="heading grey--text text--darken-4"
                          >close</v-icon
                        >
                      </v-btn>
                    </v-card-title>

                    <v-container
                      bg
                      fill-height
                      grid-list-md
                      text-xs-center
                      class="title-layout-size"
                    >
                      <v-layout>
                        <v-flex md12>
                          <v-card-title class="title-layout-size">
                            <span>Reason for cancelling Advance*</span>
                          </v-card-title>
                          <v-card-text class="title-layout-size">
                            <v-layout>
                              <v-flex pt-2 pr-1>
                                <v-select
                                  class="pt-0 currencyTitle"
                                  v-model="cancelType"
                                  :items="cancellationType"
                                  label="Select your option"
                                  persistent-hint
                                  :menu-props="{ offsetY: true }"
                                  return-object
                                  single-line
                                ></v-select>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex>
                                <v-text-field
                                  class="pt-0 currencyTitle"
                                  v-if="cancelType.value == 'Other’s'"
                                  type="text"
                                  single-line
                                  label="Enter your reason"
                                  required
                                  v-model="cancellationReason"
                                  :value="cancellationReason"
                                />
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-container
                      bg
                      fill-height
                      grid-list-md
                      text-xs-center
                      class="title-layout-size"
                    >
                    </v-container>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" flat @click="closeAdvance()"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="orange darken-1"
                        flat
                        :disabled="loading"
                        @click="cancelAdvance()"
                        >Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
                <v-snackbar
                  :timeout="3000"
                  bottom
                  color="red darken-2"
                  v-model="x.error"
                  class="white--text"
                  v-if="x.error"
                  >{{ x.error }}</v-snackbar
                >
              </v-dialog>

              <v-snackbar
                :timeout="3000"
                color="red darken-2"
                v-model="error"
                class="white--text"
                v-if="error"
                >{{ error }}</v-snackbar
              >
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import Loading from "vue-loading-overlay";
import { generateAdvanceList } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import activityLogs from "../Common/activityLogs.vue";
import AdvancePaid from "@/components/Accounts/Popups/AdvancePaid";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import ReadyToPayPopup from "@/components/Pop-ups/ReadyToPayPopup.vue";
import editAdvance from "@/components/Pop-ups/editAdvance";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
  CancellationType,
} from "../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    this.customerStatus = this.$props.viewOnly;
    this.cancellationType = CancellationType;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.payload = {
      skip: "0",
      limit: "15",
      assignmentId: this.$props.assignId,
      workingCountry: this.workingCountry,
    };
    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.x.error = "Please check your internet connection";
      setTimeout(() => {
        this.x.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    activityLogs,
    editAdvance,
    GenerateAdvance,
    ReadyToPayPopup,
    ProofOfDelivery,
    AdvancePaid,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
    assignId: String,
    advancePaid: Number,
    additionalCharges: Number,
    viewOnly: Boolean,
    section: String,
    isVisible: Number,
  },

  data() {
    return {
      opeartionName: "",
      workingCountry: null,
      count: 0,
      rejectDialog: false,
      rejectReason: null,
      assignedToTransporter: "",
      assignDetails: {},
      customerStatus: "",
      bankName: "",
      ownerType: "",
      purchaseInvoiceGenerated: false,
      advanceId: null,
      JobTypes: [],
      invoiceCompany: "",
      invoiceCity: "",
      totaldata: "",
      advanceAmount: null,
      totaladvancePaid: null,
      advanceStatus: "",
      currency: "",
      cancellationType: [],
      cancellationReason: "",
      cancelType: "",
      status: null,
      jobType: "",
      jobId: "",
      assignID: null,
      isRejectTrue: false,
      paymentAmount: null,
      assignmentDetails: {},
      isMarkedAllRead: false,
      maximumAdvanceLeftAmount: 0,
      totalAdvanceRequested: [],
      error: "",
      valid: true,
      lazy: false,
      cancelDialog: false,
      isCancelAll: null,
      cancelreason: "",
      loading: false,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "The advance has been successfully cancelled",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {},

      headers: generateAdvanceList,
      items: [],
    };
  },
  watch: {},
  methods: {
    getStatus(status) {
      switch (status) {
        case "Paid":
          return "Paid";
        case "Issued":
          return "Requested";
        case "Rejected":
          return "Rejected";
        case "Cancel":
          return "Cancelled";
        case "Ready":
          return "Ready To Pay";
        case "PartiallyPaid":
          return "Partial Paid";

        default:
          return "Unknown";
      }
    },
    refreshList() {
      this.$emit("assignment-list-refresh");
      this.componentKey++;
    },
    getBankName() {
      if (this.invoiceCity == "1" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Dammam";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Jeddah";
      } else if (this.invoiceCity == "3" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Riyadh";
      } else if (this.invoiceCity == "1" && this.invoiceCompany == "2") {
        this.bankName = "Mashreq Bank PSJC";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "2") {
        this.bankName = "EMIRATES NBD Bank";
      }
      return this.bankName;
    },
    getList() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      this.dialog = true;
      this.payload = {
        skip: "0",
        limit: "15",
        assignmentId: this.$props.assignId,
        workingCountry: this.workingCountry,
      };
    },
    openReject(type) {
      this.rejectDialog = true;
    },
    async check(type, id) {
      if (this.$route.name == "Accounts")
        this.operationName = "add-advance-for-accounts";
      if (this.$route.name != "Accounts") this.operationName = "cancel-advance";

      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.cancelDialog = true;
        this.isCancelAll = type;
        this.advanceId = id;
      } else {
        return;
      }
    },
    closeAdvance() {
      this.cancelDialog = false;
      this.cancelType = "";
      this.cancellationReason = "";
    },
    closeReject() {
      this.rejectDialog = false;

      this.rejectReason = "";
    },
    async cancelAdvance() {
      this.loading = true;
      this.operationName = "cancel-advance";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (!this.cancelType) {
          this.error = "Please select cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          this.loading = false;
          return false;
        }
        if (this.cancelType.value == "Other’s") {
          if (!this.cancellationReason.trim()) {
            this.error = "Please enter cancellation reason before moving ahead";
            setTimeout(() => {
              this.x.error = "";
            }, 3000);
            this.loading = false;
            return false;
          }
        }
        const data = {
          assignmentId: this.$props.assignId,
        };
        if (this.isCancelAll == "single") {
          data.advanceId = this.advanceId;
          data.isCancelAll = false;
        }
        if (this.isCancelAll == "all") {
          data.isCancelAll = true;
        }
        if (this.cancelType.value) {
          if (this.cancelType.value == "Other’s") {
            data.cancelAdvanceReason =
              this.cancelType.value + " - " + this.cancellationReason;
          } else {
            data.cancelAdvanceReason = this.cancelType.value;
          }
        }

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        this.axios.post(`${apiUrl}/dmsAdmin/cancelAdvance`, data, config).then(
          (response) => {
            const { data } = response.data;
            this.x.success = true;
            this.cancelDialog = false;
            this.cancelType = "";
            this.cancellationReason = "";
            this.loading = false;
            this.$route.name != "Accounts"
              ? this.$eventBus.$emit("refresh-job")
              : "";
            this.$emit("account-list-refresh");
            this.$emit("assignment-list-refresh");
          },
          (error) => {
            this.error = error.response.data.message;
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },

    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
      this.$emit("assignment-list-refresh");
      this.$emit("account-list-refresh");
    },
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    getTime4(date) {
      return moment(date).format("ll");
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    callback(res) {
      this.loading = false;
      this.totalAdvanceRequested = [];
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.assignDetails = res.assignmentDetails[0];
      this.advanceAmount = res.assignmentDetails[0].advanceAmount;
      this.totaladvancePaid = res.assignmentDetails[0].totalAdvancePaidAmount;
      this.assignedToTransporter =
        res.assignmentDetails[0].assignedToTransporter;
      this.maximumAdvanceLeftAmount =
        res.assignmentDetails[0].maximumAdvanceLeftAmount;
      this.totaldata = res.assignmentDetails[0].totalAmount;
      this.ownerType = res.assignmentDetails[0].ownerType;
      this.purchaseInvoiceGenerated =
        res.assignmentDetails[0].isPurchaseInvoiceGenerated;

      console.log(res);
      res.list.filter((x) => {
        if (x.advanceStatus != "Cancel" && x.advanceStatus != "Paid") {
          this.totalAdvanceRequested.push(x.advanceAmount);
        }
      });
      this.isMarkedAllRead = res.isReadyToPay;
      this.invoiceCity = res.invoicingCity;
      this.assignmentDetails = res.assignmentDetails[0];
      this.invoiceCompany = res.invoicingCompany;
      this.$eventBus.$emit("off-load");
      this.paymentAmount = res.assignmentDetails[0].paymentAmount;
      this.currency = res.assignmentDetails[0].customerCurrency;
      this.advanceStatus = res.isCancel;

      this.assignID = res.assignmentDetails[0].assignmentId;
      this.status = res.assignmentDetails[0].assignmentStatus;
      this.items = res.list;
      this.getBankName();
      this.totalPages = Math.ceil(res.totalData / 15);
      // window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.pd__left__4 .v-toolbar__content {
  padding-left: 10px !important;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.p-16 {
  padding: 8px !important;
}

.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;

  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}
.reject-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.p-b-0 {
  padding-bottom: 0px !important ;
}
.p-t-0 {
  padding-top: 0px !important ;
}
.text-stylee {
  font-size: 20px;
}
</style>
