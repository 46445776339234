<template>
  <div class="log">
    <v-btn
      class="ma-2"
      outline
      color="orange"
      @click.stop="dialog = false"
      :disabled="processing || status <= 1 || viewOnly == 'view' || status == 8"
      @click="check()"
      target="_blank"
    >
      + Add Additional Charges
    </v-btn>
    <v-dialog v-model="dialog" max-width="60%" persistent>
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Add Additional Charges</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout>
                  <v-flex
                    class="pr-3"
                    xs12
                    md4
                    v-if="
                      chargesType == 'Inclusive' &&
                      costMargin != '0' &&
                      miscellaneousChargeShow != true
                    "
                  >
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="priceType"
                      row
                      :mandatory="true"
                      :rules="[rules.required]"
                    >
                      <div>
                        <div class="subheading muted caption">
                          Prices are exclusive/inclusive*
                        </div>
                        <div class="heading">
                          <v-layout>
                            <v-radio
                              label="Exclusive"
                              value="Exclusive"
                              color="black"
                              class="col-md-6"
                            ></v-radio>
                            <v-radio
                              label="Inclusive"
                              value="Inclusive"
                              color="black"
                              class="col-md-6"
                            ></v-radio>
                          </v-layout>
                        </div>
                        <div
                          class="subheading muted caption"
                          v-if="priceType == 'Inclusive'"
                        >
                          *All additional prices will not be included in
                          invoices.
                        </div>
                        <div
                          class="subheading muted caption"
                          v-if="priceType == 'Exclusive'"
                        >
                          *All additional prices will be included in invoices.
                        </div>
                      </div>
                    </v-radio-group>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md6>
                    <v-switch
                      color="green"
                      v-model="miscellaneousChargeShow"
                      :label="'Add Miscellaneous Charge'"
                      @change="cleanData()"
                    ></v-switch>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex xs3 pt-2>
                    <v-text-field
                      label="Assignment Id"
                      :disabled="ids ? true : false"
                      v-model="assId"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs3 pt-2 pl-3>
                    <v-autocomplete
                      v-model="descriptionType"
                      :items="additionalChargeList"
                      label="Select description type"
                      placeholder="Select description type"
                      persistent-hint
                      :disabled="processing"
                      :menu-props="{ offsetY: true }"
                      return-object
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs3 pt-2 pl-3 v-if="!miscellaneousChargeShow">
                    <v-text-field
                      v-if="!miscellaneousChargeShow"
                      label="Customer Price"
                      placeholder="Customer Price"
                      type="number"
                      max="1000000"
                      :suffix="currency1"
                      :disabled="processing"
                      v-model="costMargin"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 pt-2 pl-3 pr-2 v-if="!miscellaneousChargeShow">
                    <v-text-field
                      label="Driver Charges"
                      placeholder="Driver Charges"
                      type="number"
                      max="1000000"
                      :suffix="currency1"
                      :disabled="processing"
                      v-model="amount"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 pt-2 pl-3 pr-2 v-if="miscellaneousChargeShow">
                    <v-text-field
                      label="Miscellaneous Charges"
                      placeholder="Miscellaneous Charges"
                      type="number"
                      max="1000000"
                      :suffix="currency1"
                      :disabled="processing"
                      v-model="miscellaneousCharge"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <!-- <v-flex xs4>
                    <v-select
                      v-model="descriptionType"
                      :items="descriptionTypeList"
                      label="Select description type"
                      persistent-hint
                      :disabled="processing || this.$props.currency == 'PKR'"
                      :menu-props="{ offsetY: true }"
                      return-object
                      single-line
                    ></v-select>
                  </v-flex> -->

                  <v-flex xs12>
                    <v-text-field
                      label="Comment"
                      placeholder="Comment"
                      :disabled="processing"
                      type="text"
                      v-model="comments"
                      autocomplete="off"
                      counter
                      maxlength="75"
                    ></v-text-field>
                    <!--                    <v-flex xs1></v-flex>-->
                  </v-flex>
                  <!-- <v-flex
                    xs12
                    pl-2
                    v-if="
                      descriptionType == 'Others' &&
                        this.$props.currency == 'SAR'
                    "
                  >
                <v-text-field
                      label="Description in arabic
                      (optional)"
                      :disabled="processing"
                      type="text"
                      v-model="commentsArabic"
                      autocomplete="off"
                      counter
                      maxlength="75"
                    ></v-text-field> -->
                </v-layout>
                <!-- <v-layout row>
                  <v-flex xs4>
                    <v-text-field
                      label="Charges"
                      type="number"
                      min="1"
                      max="1000000"
                      :disabled="processing"
                      v-model="amount"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>
                  <v-text-field
                    label="Currency"
                    readonly
                    :disabled="processing"
                    v-model="currency1"
                  ></v-text-field>

                  <v-flex xs1></v-flex>
                </v-layout> -->
                <v-flex xs12 class="marginTop20">
                  <ul style="position: relative">
                    <li
                      :key="i"
                      v-for="(doc, i) in document"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="document"
                        @click="removeImage(i)"
                      >
                        <span class="imageClose">&times;</span>
                      </button>

                      <a
                        :href="doc"
                        class="document-link"
                        v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                        target="_blank"
                      >
                        <img src="../../assets/pfd.png" />
                      </a>
                      <a
                        :href="doc"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <!-- <v-icon
                        color="red darken-1"
                        flat
                        size="40"
                        prepend-icon
                        title="Document"
                        >photo_size_select_actual</v-icon
                      > -->
                        <img
                          src="../../assets/docimg.webp"
                          height="72"
                          width="75"
                        />
                      </a>

                      <!-- <a :href="doc" class="document-link" target="_blank">
                      <img class="thumb" :src="doc" />

                      <button v-show="document" @click="removeImage(i)">
                        <v-icon class="imageClose">close</v-icon>
                      </button>
                    </a>-->
                    </li>
                  </ul>

                  <div class="upload-btn-wrapper">
                    <button v-if="document.length < 30" class="btn">
                      + Additional Charges Proof
                    </button>
                    <input
                      :disabled="processing"
                      ref="fileUpload"
                      id="fileUpload"
                      type="file"
                      multiple
                      accept=".pdf, .jpeg, .jpg, .png"
                      @change="uploadDocument"
                    />
                  </div>
                </v-flex>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-snackbar
                :timeout="3000"
                bottom
                color="red darken-2"
                v-model="x.error"
                class="white--text"
                v-if="x.error"
                >{{ x.error }}</v-snackbar
              >
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                :disabled="processing"
                flat
                @click="close"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                :disabled="processing"
                flat
                @click="checkStatus(customerId, amount)"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="dialog1"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 100px"
          flat
        >
          <v-toolbar-title>Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialog1 = false"
              :disabled="processing"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text
            style="text-align: center"
            v-show="!!creditLimitError"
            class="pa-4"
          >
            <div class="pb-4">
              <img
                src="../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div>
            <div style="color: red">{{ creditLimitError }}</div>
            <div>Are you sure want to add additional charges?</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5 justfy_c">
            <v-btn
              medium
              style="text-transform: none; !important"
              color="orange white--text "
              darken-1
              :loading="processing"
              text
              @click.native="addCharges()"
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
    <!-- <success-dialog
      content="The proof of additional charge has been uploaded successfully"
      :show="x.success1"
      :onclose="() => (x.success1 = false)"
    /> -->
  </div>
</template>
<script>
import moment from "moment";
import Swal from "sweetalert2";
import SuccessDialog from "@/components/Common/SuccessDialog";
import {
  addAdditonalCharges,
  checkCreditLimit,
  additonalChargesAssignmentList,
  uploadImage,
  getAdditionalChargeList,
} from "@/constants/api-urls.js";
import { StorageKeys } from "../../constants/constants";
export default {
  data() {
    return {
      descriptionType: "",
      descriptionTypeList: [
        "Toll Charges",
        "Sila Charges",
        "Batha Charges",
        "Detention Charges",
        "Token Charges",
        "Customs Clearance Charges",
        "Weightment Charges",
        "Bahrain Causeway Charges",
        "Inspection Charges",
        "Others",
      ],
      additionalChargeList: [],
      customerStatus: "",
      creditLimitError: "",
      priceType: "Exclusive",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      operationName: "",
      AssignmentId: [],
      ids: "",
      currency1: "",
      documents: [],
      document: [],
      x: {
        error: null,
        success1: false,
      },
      amount: "",
      costMargin: "",
      miscellaneousCharge: "",
      comments: null,
      commentsArabic: null,
      items: [],
      processing: false,
      miscellaneousChargeShow: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
    };
  },
  watch: {
    amount(val) {},
  },
  props: {
    jobId: String,
    assId: Number,
    charges: Number,
    status: Number,
    chargesType: String,
    viewOnly: Boolean,
    currency: String,
    assign_id: String,
    customerId: Number,
    sourceCountry: String,
    destinationCountry: String,
    destinationCity: String,
    VendorWorkingCountry: String,
  },
  created() {
    window.addEventListener("offline", () => {
      this.close();
    });

    if (!navigator.onLine) {
      this.loading = false;
      this.x.error =
        "Your internet connection seems to be offline.Please try again.";
      setTimeout(() => {
        this.x.error = "";
      }, 3000);
      return;
    }
    this.customerStatus = localStorage.getItem("cust-status");

    this.currency1 = this.$props.currency.toString() || "";
    // if (this.$props.currency == "PKR") {
    //   this.descriptionType = "Others";
    // }
    this.ids = this.$props.assId;
    // this.getActivityLog();
  },
  components: {
    SuccessDialog,
  },
  methods: {
    async check() {
      console.log();
      this.processing = true;
      if (this.$route.name == "Accounts")
        this.operationName = "additional-charge-for-accounts";
      if (this.$route.name != "Accounts")
        this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
        this.loading = false;
        this.processing = false;
        // console.log(this.destinationCountry);
        // console.log(this.destinationCity);
        // console.log(this.VendorWorkingCountry);

        this.fetchAdditionalChargeList();

        this.amount = "";
        this.costMargin = "";
        if (this.$props.currency) {
          this.currency1 = this.$props.currency;
          // if (this.$props.currency == "PKR") {
          //   this.descriptionType = "Others";
          // }
          this.priceType = "Exclusive";
        } else {
          this.processing = false;
          return;
        }
      }
    },

    fetchAdditionalChargeList() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;

      let obj = [];
      obj.push(`country=${this.VendorWorkingCountry}`);

      obj.push(`destination=${this.destinationCountry}`);
      obj.push(`source=${this.sourceCountry}`);

      let Url = getAdditionalChargeList;
      let params = obj.join("&");
      Url = Url + params;

      this.axios.get(this.constants.apiUrl + Url).then((response) => {
        let data = [];
        data = response.data.data;
        // data.map((e) => e.name);

        data.forEach((element) => {
          this.additionalChargeList.push(element.name);
        });
      });
    },
    cleanData() {
      this.costMargin = "";
      this.amount = "";
      this.miscellaneousCharge = "";
    },
    checkStatus(id, amount) {
      if (!navigator.onLine) {
        this.dialog = false;
        this.processing = false;
        this.loading = false;
        Swal.fire({
          title: "Oops!",
          text: "Your internet connection seems to be offline.Please try again.",
          type: "error",
          allowOutsideClick: false,
          confirmButtonColor: "orange",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.value) {
            this.processing = false;
            this.loading = false;
          }
        });
      }

      if (this.$refs.form.validate()) {
        this.processing = true;
        if (!this.assId) {
          this.processing = false;
          this.x.error = "Please select assignment id";
          return false;
        }

        if (!this.miscellaneousChargeShow) {
          if (!this.amount) {
            this.processing = false;
            this.x.error = "Please provide  driver charges";
            return false;
          }
          if (!this.costMargin) {
            this.processing = false;
            this.x.error = "Please provide  customer price";
            return false;
          }
          if (this.amount) {
            if (this.amount >= 1000000) {
              this.processing = false;
              this.x.error =
                "Driver charges must be less than or equal to 1000000 ";
              return false;
            }
            if (!this.amount.match(/^\d+(?:\.\d{1,2})?$/)) {
              this.processing = false;
              this.x.error = "Driver charges must be upto 2 decimal places";
              return false;
            }
            // if (this.amount < 1) {
            //   this.processing = false;
            //   this.x.error =
            //     "Driver charges must be greater than or equal to 1  ";
            //   return false;
            // }
          }
          if (this.costMargin) {
            if (this.costMargin >= 1000000) {
              this.processing = false;
              this.x.error =
                "Customer Price must be less than or equal to 1000000 ";
              return false;
            }
            // if (this.costMargin < 1) {
            //   this.processing = false;
            //   this.x.error =
            //     "Customer Price must be greater than or equal to 1  ";
            //   return false;
            // }
            if (!this.costMargin.match(/^\d+(?:\.\d{1,2})?$/)) {
              this.processing = false;
              this.x.error = "Customer Price must be upto 2 decimal places";
              return false;
            }
          }
        } else {
          if (
            !this.miscellaneousCharge ||
            parseFloat(this.miscellaneousCharge) == 0
          ) {
            this.processing = false;
            this.x.error = "Please provide miscellaneous charges";
            return false;
          }
          if (this.miscellaneousCharge) {
            if (this.miscellaneousCharge >= 1000000) {
              this.processing = false;
              this.x.error =
                "Miscellaneous Charges must be less than or equal to 1000000 ";
              return false;
            }
            if (!this.miscellaneousCharge.match(/^\d+(?:\.\d{1,2})?$/)) {
              this.processing = false;
              this.x.error =
                "Miscellaneous Charges must be upto 2 decimal places";
              return false;
            }
            // if (this.amount < 1) {
            //   this.processing = false;
            //   this.x.error =
            //     "Driver charges must be greater than or equal to 1  ";
            //   return false;
            // }
          }
        }

        if (!this.descriptionType) {
          this.processing = false;
          this.x.error = "Please select description type before moving ahead";
          return false;
        }
        if (
          this.descriptionType &&
          this.descriptionType == "Others" &&
          (!this.comments || !this.comments.trim())
        ) {
          this.processing = false;
          this.x.error = "Please provide description text before moving ahead";
          return false;
        }
        if (
          parseFloat(this.amount) > parseFloat(this.costMargin) &&
          parseFloat(this.costMargin) !== 0
        ) {
          this.processing = false;
          this.x.error =
            "The Customer Price amount should be equal or greater than the driver charges.";
          return false;
        }
        if (
          parseFloat(this.amount) < 1 &&
          parseFloat(this.costMargin) < 1 &&
          !this.miscellaneousChargeShow
        ) {
          this.processing = false;
          this.x.error = "Please fill either customer price or driver charges ";
          return false;
        }
        if (this.amount) {
          this.loading = true;
          let url = checkCreditLimit;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {
            userId: id.toString(),
            price: amount,
            oldPrice: "0",
            // inquiryStatus:status
          };
          // if(this.statusComments){
          //   body.comments = this.statusComments.trim();
          // }
          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              if (response.status === 200) {
                this.addCharges();

                this.dialog = false;
                this.processing = false;
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              this.processing = false;

              if (error.response.data.statusCode === 400) {
                this.x.error = error.response.data.message;
                this.dialog = false;
              }
              if (error.response.data.statusCode === 402) {
                this.x.error = error.response.data.message;
                this.dialog1 = true;
                this.creditLimitError = error.response.data.message;
                this.dialog = false;
              } else {
                this.x.error = error.response.data.message;
                this.dialog = false;
              }
              // setTimeout(() => {
              //   this.x.error = "";
              // }, 3000);
            }
          );
        } else {
          this.addCharges();
        }
      }
    },
    assigments() {
      this.loading = true;
      const data = {
        JobId: this.$route.params.id,
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}${additonalChargesAssignmentList}`, data, config)
        .then(
          (response) => {
            const { data } = response.data;
            this.count = response.data.data.count;
            this.AssignmentId = response.data.data.data;
            this.loading = false;
          },
          (error) => {
            this.error = error.response.data.message;
            // this.dailog = false;
            this.loading = false;
          }
        );
    },

    removeImage(key) {
      this.document.splice(key, 1);
    },
    close() {
      this.dialog = false;
      this.document = [];
      this.additionalChargeList = [];
      this.priceType = "Exclusive";
      this.amount = "";
      this.$refs.form.resetValidation();
      this.comments = "";
      this.commentsArabic = "";

      this.descriptionType = "";
    },
    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);

      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 30) {
              this.document.push(response.data.data.original);
              return;
            } else {
              this.x.error = "Maximum 30 proofs can be uploaded!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    async addCharges() {
      // this.processing = false;
      //    this.operationName = "edit-additional-charges";
      if (this.$route.name == "Accounts")
        this.operationName = "additional-charge-for-accounts";
      if (this.$route.name != "Accounts")
        this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = true;
        let url = addAdditonalCharges;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        var body = {
          assignmentId: this.assign_id.toString(),
          comments: this.comments,

          jobId: this.$props.jobId.toString(),
          documents: this.document,
          chargesType: this.priceType || "Exclusive",
        };
        if (this.comments) {
          body.description = this.comments;
        }
        if (this.amount) {
          if (Number(this.amount) == 0) {
            body.amount = "0";
          } else {
            body.amount = this.amount;
          }
        }

        if (this.costMargin) {
          if (Number(this.costMargin) == 0) {
            body.costMargin = "0";
          } else {
            body.costMargin = this.costMargin;
          }
        }
        if (this.miscellaneousCharge) {
          if (Number(this.miscellaneousCharge) == 0) {
            body.miscellaneousCharge = "0";
          } else {
            body.miscellaneousCharge = this.miscellaneousCharge;
          }

          // body.isMiscellaneousCharge = this.miscellaneousChargeShow;
        }

        if (this.descriptionType) {
          if (this.descriptionType == "Others") {
            body.others = true;
            body.comments = this.comments;
            // if (this.$props.currency == "SAR") {
            //   body.commentsArabic = this.commentsArabic;
            // }
          } else {
            body.others = false;
            body.comments = this.descriptionType;
          }
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            var amount = this.amount;
            this.processing = false;
            this.loading = false;
            this.dialog = false;
            this.dialog1 = false;
            this.$eventBus.$emit("additional-charges-added", amount);
            // this.$eventBus.$emit("success");
            this.$emit("successAdditionalCharges");
            this.amount = "";
            this.costMargin = "";
            this.miscellaneousCharge = "";
            this.miscellaneousChargeShow = false;
            this.dialog = false;
            this.comments = null;
            this.commentsArabic = null;

            this.descriptionType = "";

            this.x.success1 = true;
            this.document = [];
          },
          (error) => {
            this.processing = false;
            this.loading = false;
            this.dialog = true;
            this.x.error = error.response.data.error;
          }
        );
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.p-16 {
  padding: 16px !important;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper a {
  margin-left: 10px;
}
.upload-btn-wrapper .btn,
.upload-btn-wrapper .view-btn {
  border: 2px solid darkorange;
  color: darkorange;
  background-color: white;
  padding: 8px 20px;
  cursor: pointer !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.activityLog {
  margin: 0 auto;
  width: 80%;
}

.activity {
  margin: auto;
}
.tooltip {
  font-size: 18px;
  font-weight: 500;
}
.tooltip-op {
  left: calc(100% - 9.2%) !important;
  opacity: 1 !important;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.document-link {
  text-decoration: none;
}
.ttt button select {
  border: none !important;
}
.imageClose {
  position: absolute;
  width: 15px;
  bottom: 80px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  color: #fff !important;
  line-height: 15px;
  top: -6px;
  left: 2px;
}

.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: inline-block;
  position: relative;
}
.thumb {
  width: 80px;
  height: 80px;
}

.custom-file-upload {
  border: none;
  display: inline-block;
  background: orange;
  font-size: 16px;
  color: #fff;
  padding: 10px 12px;
  cursor: pointer;
}
</style>
